<template>
    <section class="section-ver-visita">
        <div class="row my-3 titulo-divisor">
            <div class="col-auto my-auto d-middle-center">
                <i class="icon-arrow-left-circle cr-pointer icon-general-hover f-18" @click="goBack" />
                <p class="f-600 f-14 ucfirst"> Visita {{ numero }}</p>
            </div>
            <div class="col my-auto pl-0">
                <hr class="my-0 bg-white" />
            </div>
            <div v-if="!visitaFinalizada" class="col-auto">
                <button class="btn btn-general text-white f-12 px-3" @click="openEndVisit">Finalizar visita</button>
            </div>
            <div class="col-auto my-auto">
                <el-tooltip content="Comentarios" effect="light" visible-arrow>
                    <i class="icon-message-reply-text cr-pointer f-18 icon-general-hover" @click="comentarVisita" />
                </el-tooltip>
                <el-tooltip v-if="!visitaFinalizada" content="Editar" effect="light" visible-arrow>
                    <i class="icon-pencil cr-pointer f-18 icon-general-hover" @click="editarVisita(visitDetails)" />
                </el-tooltip>
                <el-tooltip content="Eliminar" effect="light" visible-arrow>
                    <i class="icon-trash-can-outline cr-pointer f-18 icon-general-hover" @click="deleteVisita(visitDetails.id)" />
                </el-tooltip>
            </div>
            
        </div>
        <!-- info  -->
        <div v-if="visitDetails" class="row mx-3 bg-f5 py-2">
            <div class="col-auto">
                <card-mini-user class="my-2" tamanoImg="46" fw="f-600"
                :img="visitDetails.foto_usuario" 
                :nombre="visitDetails.nombre_usuario" 
                :cargo="visitDetails.cargo" />
            </div>
            <div class="col-auto ml-auto d-flex my-auto">
                <p class="f-12 text-general my-auto">
                    Logros de la visita:  
                    <span class="f-600"> {{ visitDetails.actividades_terminadas }} actividades</span> 
                </p>
                <progress-ring class="mx-3" size="small" :percentage="visitDetails.porcentaje_avance" wh="50" />
            </div>
            <div class="col-auto my-auto">
                <div class="d-flex border br-l-4 bg-white br-4">
                    <div class="bg-5d text-white wh-36 d-middle-center br-l-5">
                        <i class="icon-calendar-today f-20" />
                    </div>
                    <div class="my-auto f-11 px-3">
                        <p class="f-600">Fecha</p>
                        <p class="text-capitalize">{{ visitDetails.fecha_hora_visita | helper-fecha('DD MMM YYYY') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-auto my-auto">
                <div class="d-flex border br-l-4 bg-white br-4">
                    <div class="bg-5d text-white wh-36 d-middle-center br-l-5">
                        <i class="icon-clock-time-eight-outline f-20" />
                    </div>
                    <div class="my-auto f-11 px-3">
                        <p class="f-600">Hora</p>
                        <p>{{ visitDetails.fecha_hora_visita | helper-fecha('h:mm a') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid my-4">
            <div class="row d-none">
                <div class="col-12">
                    <!-- btn finalizar  -->
                    <div class="row mx-4 justify-content-end mb-4">
                        <div class="col-auto">
                            <button class="btn btn-general text-white f-12 px-5" @click="openEndVisit">Finalizar visita</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <el-collapse v-model="activeName" class="check-list-visit">
                        <el-collapse-item v-for="(data,idx) in visitDetails.lista_chequeo" :key="idx" :name="`${data.id}`">
                            <template slot="title">
                                <p class="col-12 text-general f-600 f-14"> Check list - {{ data.nombre }} </p>
                            </template>
                            <!-- listado actividades -->
                            <div class="row mx-4 f-600 text-general f-12">
                                <div class="col-lg-1 text-center"> Item </div>
                                <div class="col-lg-4">
                                    <p>Actividad</p>
                                </div>
                                <div class="col-lg-1 text-center f-12">
                                    <p>Peso</p>
                                </div>
                                <div class="col-lg-2 text-center f-12">
                                    <p>% Avance</p>
                                </div>
                                <div class="col-lg-3 text-center f-12">
                                    <p>Archivos</p>
                                </div>
                                <div class="col-lg-1 text-center f-12"> </div>
                            </div>
                            <div v-for="(data,index) in data.actividades" :key="index" class="actividad mx-4 text-5d border py-2">
                                <check-list-activity
                                :index="index"
                                :id="data.id"
                                :idActividad="data.id_actividad"
                                :nombre="data.nombre"
                                :peso="data.peso"
								:visitaFinalizada="visitaFinalizada"
                                :adjuntosCantidades="data.archivos"
                                :porcentaje_avance="data.porcentaje_avance"
                                @addPercentage="handleAddPercentage"
                                @getAllFiles="handleGetAllFilesAct"
                                @adjuntarArchivoAct="openModalAttachFile($event, data.id_actividad)"
                                @comentarVisitaAct="handleComentarAct"
                                @verImagenes="openModalImages"
                                @getAllPdfFiles="handleGetAllPdfFilesAct"
                                @getAllVideoFiles="handlegetAllVideoFilesAct"
                                 />
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
        
       
        <!-- partials  -->
        <modal-register-visit ref="openModalRegisterVisit" />
        <modal-edit-visit ref="openModalEditVisit" @reload="getVisitDetails" />
        <modal-eliminar ref="openModalDeleteVisit" title="Eliminar visita" mensaje="¿Está seguro que quiere eliminar esta visita?" @delete="handleDeleteVisit" />
        <modal-end-visit ref="openModalEndVisit"  @finalizarVisita="endVisita" />
        <modal-comments
			ref="openModalComments"
			titulo="Comentarios"
			textVacio="No se han realizado comentarios de la visita"
			:comentar="!visitaFinalizada"
			:imgUser="user.foto_perfil | helper-storage"
			:comments="visitDetailsComments"
			@addComment="handleAddCommentVisit"
		/>
        <modal-comments ref="openModalCommentsAct" titulo="Comentarios" :comentar="!visitaFinalizada" :comments="visitDetailsCommentsAct" @addComment="handleAddCommentVisitAct" />
        <modal-all-files ref="modalAllFiles" />
        <modal-subir-achivos-actividad ref="modalSubirArchivos" @subirArchivos="subirArchivos" />
        <modal-image-gallery ref="openModalGallery" />
        <modal-list-videos ref="openModalPreviewVideos" />
    </section>
</template>

<script>
import Aws from '~/services/aws'
import modalRegisterVisit from './partials/modalRegisterVisit'
import modalEditVisit from './partials/modalEditVisit'
import modalEndVisit from './partials/modalEndVisit'
import modalAllFiles from '../partials/modalAllFiles'
import modalListVideos from '../partials/modalListVideos.vue'
import modalSubirAchivosActividad from '../partials/modalSubirAchivosActividad'
import checkListActivity from './components/checkListActivity'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'VisitDetails',
    components: {
        modalRegisterVisit,
        modalEditVisit,
        modalEndVisit,
        modalAllFiles,
        modalListVideos,
        modalSubirAchivosActividad,
        checkListActivity,
    },
    data() {
        return {
            activeName: '',
            visitToDelete: null,
            id_proyecto: this.$route.params.id_proyecto,
            id_control_piso: this.$route.params.id_control_piso,
            id_control_piso_visita: this.$route.params.id_visita,
            idActToComment: '',
            idActToAttach: '',
            idActividad: '',
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            visitDetails: 'obras/servicios/visitas/visitDetails',
            visitDetailsComments: 'obras/servicios/visitas/visitDetailsComments',
            visitDetailsCommentsAct: 'obras/servicios/visitas/visitDetailsCommentsAct',
        }),
		visitaFinalizada(){
			return this.visitDetails?.fecha_finalizado_visita ? true : false
		},
        numero(){
            return localStorage.getItem(this.$route.path)
        }
    },
    async mounted () {
        await this.getVisitDetails()
        await this.Action_get_visit_details_comments({
            id_proyecto:this.id_proyecto,
            id_control_piso:this.id_control_piso,
            id_control_piso_visita:this.id_control_piso_visita,
        })
        await this.Action_get_selects_encargados_users();
    },
    methods: {
        ...mapActions({
            Action_get_selects_encargados_users: 'selects/selects/Action_get_selects_encargados_users',
            Action_get_visit_details: 'obras/servicios/visitas/Action_get_visit_details',
            Action_get_visit_details_comments: 'obras/servicios/visitas/Action_get_visit_details_comments',
            Action_create_visit_comment: 'obras/servicios/visitas/Action_create_visit_comment',
            Action_delete_visit: 'obras/servicios/visitas/Action_delete_visit',
            Action_add_activity_percentage: 'obras/servicios/visitas/Action_add_activity_percentage',
            Action_get_activity_files: 'obras/servicios/visitas/Action_get_activity_files',
            Action_get_activity_comments: 'obras/servicios/visitas/Action_get_activity_comments',
            Action_create_visit_act_comment: 'obras/servicios/visitas/Action_create_visit_act_comment',
            Action_add_attachment_act: 'obras/servicios/visitas/Action_add_attachment_act',
            Action_end_visit: 'obras/servicios/visitas/Action_end_visit',
        }),
        async getVisitDetails(){
            await this.Action_get_visit_details({
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
            })
        },
        goBack() {
            this.$router.push({ name: 'obras.vigentes.servicios.visitas.listado' })
        },
        comentarVisita() {
            this.$refs.openModalComments.toggle()
        },
        editarVisita(data) {
            this.$refs.openModalEditVisit.toggle(data)
        },
        deleteVisita(idVisit) {
            this.$refs.openModalDeleteVisit.toggle()
            this.visitToDelete = idVisit
        },
        async handleDeleteVisit(){
            await this.Action_delete_visit({
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
            })
            this.goBack()
        },
        openEndVisit(){
            this.$refs.openModalEndVisit.toggle()
        },
        async handleAddCommentVisit(comentario){
            await this.Action_create_visit_comment({
                comentario: comentario,
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
            })
        },
        async handleAddPercentage(info){
            await this.Action_add_activity_percentage({
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
                id_control_piso_visitas_actividades:info.id,
                porcentaje_avance: info.porcentajeAvance
            })
        },
        async handleGetAllFilesAct(id){
            let obj = {
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
                id_control_piso_visitas_actividades:id,
            }
            const res = await this.$store.dispatch('obras/servicios/visitas/Action_get_activity_files', obj)
            if(res.adjuntos.length > 0){
                res.actividad.adjuntos = res.adjuntos
                this.$refs.modalAllFiles.toggle(res.actividad,res.seccion)
            } 
        },
        openModalAttachFile(id, id_actividad){
            this.idActToAttach = id
            this.idActividad = id_actividad 
            this.$refs.modalSubirArchivos.toggle()
        },
        async handleComentarAct(id){
            this.idActToComment = id
            let obj = {
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
                id_control_piso_visitas_actividades:id,
            }
            await this.Action_get_activity_comments(obj)
            this.$refs.openModalCommentsAct.toggle()
        },
        async handleAddCommentVisitAct(comentario){
            let obj = {
                comentario: comentario,
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
                id_control_piso_visitas_actividades:this.idActToComment,
            }
            await this.Action_create_visit_act_comment(obj)
        },
        async subirArchivos(files){
            this.$try(async () => {
                let adjunto = []
                let nombres = []
                let finalFiles = []
                if (files.length) {
                    const promesas = files.map(e => Aws.uploadFile({file: e, path: 'obras/vigentes'}))
                    adjunto = await this.resolverPromise(promesas)
                    nombres = this.getFilesNames(files)
                    adjunto.forEach((element,index) => {
                        nombres.forEach((e, idx) => {
                            if (index === idx) {
                                finalFiles.push({
                                    archivo: element,
                                    nombre_archivo: e,
                                })
                            }
                        });
                    });
                }
                const params = {
                    id_proyecto:this.id_proyecto,
                    id_control_piso:this.id_control_piso,
                    id_control_piso_visita:this.id_control_piso_visita,
                    id_control_piso_visitas_actividades:this.idActToAttach,
                    id_actividad: this.idActividad,
                    adjuntos: finalFiles
                }
                await this.Action_add_attachment_act(params)
                await this.getVisitDetails()
            });
        },
        async resolverPromise(promesas){
            const promises = await Promise.allSettled(promesas)
            return promises.map(e => {
                if (e.status === 'fulfilled'){
                    return e.value
                } 
            })
        },
        getFilesNames(archivos){
            return archivos.map(e => {
               return e.name
            })
        },
        async openModalImages(info){
            let obj = {
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
                id_control_piso_visitas_actividades:info.id,
            }
            const res = await this.$store.dispatch('obras/servicios/visitas/Action_get_activity_files', obj)
            if(res.adjuntos.length > 0){
                const imagenes = []
                res.adjuntos.forEach(el => {
                    if (el.id_tipo == 2) imagenes.push(el.archivo)
                })
                this.$refs.openModalGallery.toggle(imagenes, info.nombre)
            } 
        },
        openModalGallery(actividad){
            const imagenes = []
            actividad.adjuntos.forEach(el => {
                if (el.id_tipo == 2) imagenes.push(el.archivo)
            })
            
            this.$refs.openModalGallery.toggle(imagenes, actividad.actividad)
        },
        async handleGetAllPdfFilesAct(info){
            let obj = {
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
                id_control_piso_visitas_actividades:info.id,
            }
            const res = await this.$store.dispatch('obras/servicios/visitas/Action_get_activity_files', obj)
            if(res.adjuntos.length > 0){
                res.actividad.adjuntos = res.adjuntos
                this.$refs.modalAllFiles.toggle(res.actividad,res.seccion, info.tipo)
            } 
        },
        async handlegetAllVideoFilesAct(info){
            let obj = {
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
                id_control_piso_visitas_actividades:info.id,
            }
            const res = await this.$store.dispatch('obras/servicios/visitas/Action_get_activity_files', obj)
            if(res.adjuntos.length > 0){
                res.actividad.adjuntos = res.adjuntos
                this.$refs.openModalPreviewVideos.toggle(res.actividad.adjuntos,info.tipo)
            } 
        },
        previewVideos(adjuntos){
            //this.$refs.openModalPreviewVideos.toggle(adjuntos)
        },
        async endVisita(){
            let obj = {
                id_proyecto:this.id_proyecto,
                id_control_piso:this.id_control_piso,
                id_control_piso_visita:this.id_control_piso_visita,
                fecha_hora_final_visita: moment().format("YYYY-MM-DD HH:mm:ss")
            }
            await this.Action_end_visit(obj)
            this.$router.push({name: 'obras.vigentes.servicios.visitas.listado'})

        }
    },

}
</script>

<style lang="scss" scoped>
.section-ver-visita{
    .actividad{
        &:nth-child(even){
            background: #F1F2F4;
        }
    }
    .adj-item{
        .cant-adj{
            position: absolute;
            background-color: #B53438;
            top: -3px;
            right: -6px;
            z-index: 1;
        }
    }
}
</style>