<template>
    <modal ref="modalRegistrarVisita" titulo="Registrar visita" adicional="Continuar" @adicional="agregarRegistro">
        <ValidationObserver ref="registerVisita" tag="div" class="row justify-content-center m-3 f-15">
            <div class="col-8 my-2">
                <p class="input-label-top">Funcionario encargado de la visita</p>
                <ValidationProvider v-slot="{errors}" name="funcionario" rules="required">
                    <el-select v-model="model.id_funcionario" clearable filterable placeholder="Seleccionar funcionario" size="small" class="w-100"> 
                        <el-option v-for="item in select_encargados_users" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                    <vee-error :text="errors[0]" />
                </ValidationProvider>
            </div>
            <div class="col-8 my-2">
                <p class="input-label-top">Fecha</p>
                <ValidationProvider v-slot="{errors}"  name="fecha" rules="required">
                    <el-date-picker v-model="model.fecha_hora_visita" 
                    type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" 
                    :default-time="'08:00:00'"
                    placeholder="Seleccionar fecha" size="small" class="w-100" />
                    <vee-error :text="errors[0]" />
                </ValidationProvider>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            editing: false,
            model:{
                id_funcionario: '',
                fecha_hora_visita: '',
            },
            options: [ //eliminar despues
                {
                    value: 1,
                    label: 'Option1'
                },
                {
                    value: 2,
                    label: 'Option2'
                },
                {
                    value: 3,
                    label: 'Option3'
                },
            ],
            id_proyecto: this.$route.params.id_proyecto,
            id_control_piso: this.$route.params.id_control_piso,
        }
    },
    computed: {
        ...mapGetters({
            select_encargados_users: 'selects/selects/select_encargados_users',        
        }),
    },
    methods: {
        ...mapActions({
            Action_create_visit: 'obras/servicios/visitas/Action_create_visit',
        }),
        toggle(data){
            this.$refs.registerVisita.reset()
            if (data) {
                this.editing = true
            }
            this.$refs.modalRegistrarVisita.toggle()
        },
        async agregarRegistro(){
            let validForm = await this.$refs.registerVisita.validate()
            
            let obj = {
                id_user_visita : this.model.id_funcionario,
                fecha_hora_visita: this.model.fecha_hora_visita,
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
            }

            if (validForm) {
                if (this.editing) {
                    obj.id = this.idItem
                    //await this.Action_edit_folder(obj)
                } else {
                    const res = await this.$store.dispatch('obras/servicios/visitas/Action_create_visit', obj)
                    if(res?.id) this.$router.push({ name: 'obras.vigentes.servicios.visitas.ver', params: { id_visita: res.id }});
                }
                this.$refs.modalRegistrarVisita.toggle()
            }
        }
    }
}
</script>