<template>
    <modal ref="modalViewFiles" tamano="modal-lg" titulo="Archivos adjuntos de la actividad">
        <section class="m-3 view-files px-5">
            <div class="row justify-content-center f-15 shadow29 bg-f6 py-3 mb-5">
                <div class="col-6 f-12">
                    <p class="text-general f-500">
                        Seccion:
                        <span class="text-5d pl-3 f-300"> {{ seccion }} </span>
                    </p>
                </div>
                <div class="col-6 f-12">
                    <p class="text-general f-500 pr-3">
                        Actividadad:
                        <span class="text-5d f-300"> {{ actividad.actividad }} </span>
                        <span v-if="actividad.nombre" class="text-5d f-300"> {{ actividad.nombre }} </span>
                    </p>
                </div>
            </div>
            <div class="row text-general justify-content-center f-12 f-500 mb-3">
                <div class="col-6 text-center">
                    Nombre de archivos
                </div>
                <div class="col-auto w-110px text-center">
                    Adjunto
                </div>
                <div class="col-auto w-110px text-center">
                    Descargar
                </div>
            </div>
            <div v-for="(adjunto,idx) in actividad.adjuntos" :key="idx"  class="row f-12 justify-content-center" :class="{'border-bottom' : idx < actividad.adjuntos.length-1}">
                <div class="col-6 my-auto">
                    <p class="tres-puntos">{{ adjunto.nombre_archivo }}</p>
                </div>
                <div class="col-auto w-110px text-center my-auto">
                    <i v-if="adjunto.id_tipo == 1" id="df_gbp" class="icon-pdf-file f-18 _df_custom" @click="previewFile(adjunto)"/>
                    <i v-if="adjunto.id_tipo == 2" class="icon-image-box f-18" />
                    <i v-if="adjunto.id_tipo == 3" class="icon-video f-18"/>
                    <i v-if="adjunto.id_tipo == 4" class="icon-paperclip f-19" />
                </div>
                <div class="col-auto w-110px text-center my-auto icon-option">
                    <el-tooltip content="Descargar" effect="light" placement="left" visible-arrow>
                        <a class="icon-arrow-down-bold f-25 cr-pointer" :href="adjunto.archivo | helper-storage" target="_blank" download />
                    </el-tooltip>
                </div>
            </div>
        </section>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            seccion: '',
            actividad: {},
        }
    },
    methods: {
        toggle(actividad, seccion, tipo){
			console.log("🚀 1", actividad)
            this.actividad = actividad
            this.seccion = seccion
            if (tipo) {
                this.actividad.adjuntos = actividad.adjuntos.filter((value, index, arr) => value.tipo === tipo);
            }
            this.$refs.modalViewFiles.toggle()
        },
        setFileType(type){
            console.log("🚀 1", type)
            const types = {
                jpg: 'icon-image-box',
                pdf: 'icon-pdf-file',
                mp4: 'icon-video'
            }
            return types[type] || ''
        },
        previewFile(data){
            console.log("🚀 2: en preview", data)
            if (data.tipo === 'pdf') this.set_pdf(data.archivo)
        },
    }
}
</script>

<style lang="scss" scoped>
    .view-files{
        .bg-f6{
            background-color: #F6F6F6 ;
        }
        .w-200px{
            max-width: 200px;
            min-width: 200px;
        }
        .w-110px{
            max-width: 110px;
            min-width: 110px;
        }
    }
</style>
