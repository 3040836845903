<template>
    <modal ref="guardarArchivos" tamano="modal-md" titulo="Adjuntar archivos" adicional="Guardar" @adicional="guardarArchivos">
        <div class="my-2 mx-4">
            <drag-upload ref="upload" v-model="files" :multiple="true" />
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            files: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.upload.clearAll()
            this.$refs.guardarArchivos.toggle()
        },
        guardarArchivos(){
            if (!this.files.length) return
            this.$emit('subirArchivos', this.files)
            this.$refs.guardarArchivos.toggle()
        },
    }
}
</script>

<style lang="css" scoped>
.g{
    width: 200px;
}
</style>
