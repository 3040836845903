<template>
    <div>
        <div class="row mx-0">
            <div class="col-lg-1 border-right my-auto">
                <div class="wh-20 rounded-circle bg-general text-white f-12 d-middle-center mx-auto">
                    {{ index+1 }}
                </div>
            </div>
            <div class="col-lg-4 my-auto">
                <p class="f-12 text-5d tres-puntos">{{ nombre }}</p>
            </div>
            <div class="col-lg-1 text-center f-12 my-auto">
                <p>{{ peso }}%</p>
            </div>
            <div class="col-lg-2 text-center f-12 my-auto">
                <el-input v-model="porcentajeAvance" :disabled="visitaFinalizada" size="small" class="w-75 text-center" @change="$emit('addPercentage',{porcentajeAvance,id})" />
            </div>
            <div class="col-lg-3 text-center f-12 my-auto">
                 <div class="d-middle-center text-gray">
                    <div class="adj-item position-relative cr-pointer mx-2">
                        <div v-if="adjuntosCantidades.pdf > 0" class="wh-19 cant-adj rounded-circle text-white d-middle-center" @click="$emit('getAllPdfFiles', {id, tipo:'pdf'})">{{ adjuntosCantidades.pdf }}</div>
                        <i class="icon-pdf-file f-18" @click="$emit('getAllPdfFiles', {id, tipo:'pdf'})" />
                    </div>
                    <div class="adj-item position-relative cr-pointer mx-2">
                        <div v-if="adjuntosCantidades.videos > 0" class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ adjuntosCantidades.videos }}</div>
                        <i class="icon-video f-18" @click="$emit('getAllVideoFiles', {id, tipo:'video'})" />
                    </div>
                    <div class="adj-item position-relative cr-pointer mx-2">
                        <div v-if="adjuntosCantidades.imagenes > 0" class="wh-19 cant-adj rounded-circle text-white d-middle-center" @click="$emit('verImagenes',{id,nombre})">{{ adjuntosCantidades.imagenes }}</div>
                        <i class="icon-image-box f-18" @click="$emit('verImagenes',{id,nombre})"/>
                    </div>
                    <div class="adj-item position-relative cr-pointer mx-2">
                        <el-tooltip content="Ver todos" effect="light" visible-arrow>
                            <div>
                                <div v-if="totalAdjuntos() > 0" class="wh-19 cant-adj rounded-circle text-white d-middle-center" @click="$emit('getAllFiles', id)">{{ totalAdjuntos() }}</div>
                                <i class="icon-folder-line f-18" @click="$emit('getAllFiles', id)" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="col-lg-1 d-flex text-center f-12" my-auto> 
                <el-tooltip content="Comentarios" effect="light" visible-arrow>
                    <i class="icon-message-reply-text cr-pointer f-18 icon-general-hover" @click="$emit('comentarVisitaAct',id)" />
                </el-tooltip>
                <el-tooltip v-if="!visitaFinalizada" content="Adjuntar" effect="light" visible-arrow>
                    <i class="icon-paperclip cr-pointer f-18 icon-general-hover" @click="$emit('adjuntarArchivoAct',id)" />
                </el-tooltip>
            </div>
        </div>
        <!-- partials  -->
        <modal-comments ref="openModalComments" titulo="Comentarios de la visita" @addComment="handleAddCommentVisit" />
    </div>
</template>

<script>
export default {
    props: {
        id:{
            type: Number,
            required: true,
        },
        index:{
            type: Number,
            required: true,
        },
        nombre: {
            type: String,
            required: true,
        },
        peso: {
            required: true,
        },
        porcentaje_avance: {
            required: true,
            default: 0
        },
		visitaFinalizada: {
			type: Boolean,
			default: false
		},
        adjuntosCantidades:{
            type: Object,
            required: true
        }
    },
    mounted () {
        this.porcentajeAvance = this.porcentaje_avance
    },
    watch: {
        porcentaje_avance(val){
            this.porcentajeAvance = val
        }
    },
    data() {
        return {
            avance: '',
            total: '',
            porcentajeAvance: 0,
        }
    },
    methods: {
        comentarVisita() {
            this.$refs.openModalComments.toggle()
        },
        totalAdjuntos(){
            this.total =  this.adjuntosCantidades.pdf + this.adjuntosCantidades.videos + this.adjuntosCantidades.imagenes + this.adjuntosCantidades.otros
            return this.total
        },
        handleAddCommentVisit() {
            
        },
    }

}
</script>

<style lang="scss" scoped>
.actividad{
    &:nth-child(even){
        background: #F1F2F4;
    }
}
.adj-item{
    .cant-adj{
        position: absolute;
        background-color: #B53438;
        top: -3px;
        right: -6px;
        z-index: 1;
    }
}
</style>