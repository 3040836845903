<template>
    <modal ref="modalEndVisit" titulo="Finalizar visita" adicional="Finalizar" @adicional="action">
        <div class="row mx-0 my-3 justify-content-center f-14">
            <div class="col text-center">
                <p> ¿Está seguro que quiere finalizar esta visita? </p>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            
        }
    },
    methods: {
        toggle(){
            this.$refs.modalEndVisit.toggle()
        },
        async action() {
            this.$emit('finalizarVisita')
            this.$refs.modalEndVisit.toggle()
        }
    },
}
</script>