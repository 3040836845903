<template>
    <div
    ref="modalgbp"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="static" data-keyboard="false"
    >
        <div
        class="modal-dialog modal-dialog-centered modal-xl"
        role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <div class="w-100">
                        <div class="row">
                            <div class="col-sm-2 col-2 my-auto">
                                <div class="logo mx-auto">
                                    <img src="/img/generales/iafLogo.png" height="25" style="max-width:240px" class="obj-cover ml-2" alt="" />
                                </div>
                            </div>
                            <div class="col-sm-8 col-8 text-center my-auto">
                                <p class="modal-title text-center f-600 text-general"> Ver videos </p>
                            </div>
                            <div class="col-sm-2 col-2" />
                            <button type="button" class="close f-300" @click="closeImg">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                       
                    </div>
                </div>
                <div class="border-modal-top"></div>
                
                <div class="modal-body overflow-auto custom-scroll" style="max-height:70vh;">
                   <div v-if="filteredAdjuntos.length"  class="row justify-content-center m-3 f-15">
                        <div class="col-2 my-auto text-right">
                            <i class="icon-arrow-left-drop-circle cr-pointer f-25" @click="previousImg" />
                        </div>
                        <div class="col-8 text-center">
                            <!-- <h6>nombre:{{ filteredAdjuntos.nombre_archivo ? filteredAdjuntos.nombre_archivo : 'asda' }}</h6> -->
                            <h6>Nombre: <span class="ml-2">{{ adjunto.nombre_archivo }}</span> </h6>
                            <video class="w-100 mb-3" controls ref="video" style="height: fit-content">
                                <source v-if="adjunto.archivo" :src="adjunto.archivo | helper-storage" type="video/mp4">
                                <source v-else src="" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div class="col-2 my-auto text-left">
                            <i class="icon-arrow-right-drop-circle cr-pointer f-25" @click="nextImg" />
                        </div>
                    </div>
                    <div v-else class="row m-3">
                        <h3 class="col-12 text-center">Sin videos</h3>
                    </div>
                </div>
                <div class="border-modal-bottom"></div>
                <div class="row mx-0 justify-content-end py-2 bg-f5 br-b-5">
                    <button
                    type="button"
                    class="btn ml-1 mr-2 btn-cerrar f-300 f-12 br-4 border f-300"
                    @click="closeImg"
                    >Cancelar</button>
                </div>
            </div>
        </div>
    </div>
        
    
</template>
<script>
export default {
    data() {
        return {
            visible: false,
             filteredAdjuntos: [
                {
                    archivo:'',
                    nombre:'',
                }
            ],
            selected: 0,
        }
    },
     computed:{
        adjunto(){
            return this.filteredAdjuntos?.[this.selected] ?? {}
        }
    },
    methods: {
        toggle(adjuntos,tipo){
            $(this.$refs.modalgbp).modal('toggle')
            this.visible = !this.visible
            if(!this.visible){
                this.$emit('closed')
            }
            let newAdjuntos = adjuntos.filter((value) => value.tipo === 'video');
            this.filteredAdjuntos = newAdjuntos
        },
        nextImg(){  
            this.selected = ((this.selected++) >= (this.filteredAdjuntos.length - 1)) ? 0: this.selected++
            this.reload()
        },
        previousImg(){
            this.selected = ((this.selected--) <= 0) ? 0: this.selected--
            this.reload()
        },
        closeImg(){
            console.log('pause video');
            //this.selected = this.$refs.modalPreviewImg.toggle()
            this.$refs.video.pause()
            $(this.$refs.modalgbp).modal('toggle')
        },
        reload(){
            this.$refs.video.load()
        }
    },
}
</script>
<style lang="scss" scoped>
.modal-content{
    border: none;
    border-radius: 5px;
    .border-modal{
        &-top{
            width: 100%;
            height: 1px;
            background: var(--color-general)
        }
        &-bottom{
            width: 100%;
            height: 1px;
            background: #dbdbdb;
        }
    }
    .modal-header{
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: none;
        background-size: cover;
        padding: 1rem;
        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 8px;
            font-size: 2.5em;
            padding: 0px;
            margin: 0px;
            color: var(--color-general) !important;
            &:hover{
                transition-duration: .4s;
            }
        }
        .logo{
            display: block;
            // height: 70px;
            // width: 70px;

            span{
                font-size: 1.2em;
            }
        }
       
    }
    .modal-title{
        color: var(--color-general);
        font-weight: normal;
    }
}
.img-tipo{
    position: absolute;
    top:20px;
    left: 50%;
    transform: translate(-50%);
}
    
</style>